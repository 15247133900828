<template>
  <div class="card">
    <div class="card-header border-0 pt-6 bg-white row m-0 sticky">
      <div class="d-flex p-0">
        <div class="card-title row">
          <div class="d-flex p-0 align-items-center position-relative mb-4">
            <span class="text-gray-500 fw-bolder text-hover-primary rounded-1 cursor-pointer me-6 fs-6" @click="router.go(-1)"><i class="fas fa-arrow-left me-2 text-gray-500 fw-bolder"></i>BACK</span>
          </div>
          <div class="filter d-flex align-items-center position-relative fs-6 text-gray-600">
            <div class="filter-item">
              <span>KODE DISTRIBUSI</span>
              <el-input v-model="filter.kode_dist" placeholder="Search Kode Distribusi" clearable @change="onFilter()" />
            </div>
            <div class="d-flex">
              <button class="btn btn-icon btn-light-primary me-3" @click="onFilter()"><i class="fas fa-search"></i></button>
              <button class="btn btn-icon btn-light-danger" @click="resetFilter()"><i class="fas fa-times"></i></button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body sticky">
      <div ref="tableRef" class="table-fixed-header">
        <table class="table table-rounded table-row-dashed" :class="{'overlay': loadingTable, 'overlay-block': loadingTable}">
          <thead>
          <tr class="text-start fw-bolder fs-7 gs-0 text-gray-400 border-gray-200 text-uppercase">
            <th>#</th>
            <th>Tanggal</th>
            <th>Kode distribusi</th>
            <th>Jenis</th>
            <th>Pengirim</th>
            <th>Penerima</th>
            <th>Keterangan</th>
            <th class="text-end">Qty</th>
            <th class="text-end w-150px">status</th>
            <th class="text-end">Action</th>
          </tr>
          </thead>
          <tbody class="fw-bold text-gray-600">
          <tr v-for="(item, i) in tableData" :key="i" class="odd">
            <td scope="row">{{ item.index }}</td>
            <td class="w-150px">{{ moment(item.tanggal).format('DD/MM/YYYY HH:mm') }}</td>
            <td>{{ item.kode_dist }}</td>
            <td class="text-uppercase">
              <span class="badge badge-secondary" v-if="item.jenis == 'item'">{{ item.jenis }}</span>
              <span class="badge badge-primary" v-else-if="item.jenis == 'desain'">{{ item.jenis }}</span>
              <span class="badge badge-warning" v-else>{{ item.jenis || 'Tidak diketahui' }}</span>
            </td>
            <td>{{ item.pengirim }}</td>
            <td>{{ item.penerima }}</td>
            <td>{{ item.note }}</td>
            <td class="text-end">{{ item.total_qty }}</td>
            <td class="text-end">
              <span class="badge badge-dark" v-if="item.status == 'PENDING'">{{ item.status }}</span>
              <span class="badge badge-info" v-if="item.status == 'DIKIRIM'">{{ item.status }}</span>
              <span class="badge badge-success" v-if="item.status == 'DITERIMA'">{{ item.status }}</span>
              <span class="badge badge-warning" v-if="item.status == 'BERMASALAH'">{{ item.status }}</span>
              <span class="badge badge-danger" v-if="item.status == 'GAGAL'">{{ item.status }}</span>
            </td>
            <td class="text-end">
              <div class="d-flex justify-content-end">
                <router-link :to="'/distribusi/detail/'+item.kode_dist" class="btn btn-light-primary btn-active-primary btn-sm mx-1">
                  View
                </router-link>
                <router-link :to="'/distribusi/print/'+item.kode_dist" class="btn btn-light-dark btn-active-light-primary btn-sm mx-1">
                  Print
                </router-link>
              </div>
            </td>
          </tr>
          </tbody>
          <div class="overlay-layer card-rounded bg-dark bg-opacity-5" v-if="loadingTable">
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </table>
      </div>
      <div class="text-center my-3">
        <el-pagination
          background
          layout="prev, pager, next"
          :page-count="pagination.totalPage"
          :total="pagination.totalPage"
          @next-click="handlePageChange"
          @prev-click="handlePageChange"
          @current-change="handlePageChange"
          @update:current-page="pagination.currentPage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref, reactive, watchEffect, watch, computed, onUpdated } from "vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { formatIDR } from "@/helpers/functions";
//import TableLite from 'vue3-table-lite/src/components/TableLite.vue'
import Datatable from "@/components/kt-datatable/KTDatatable.vue"
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useRoute, useRouter } from 'vue-router'
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import moment from 'moment'
//import { InfoFilled } from '@element-plus/icons-vue'

export default defineComponent({
  setup() {
    moment.locale('id');

    const base_img_url = ref(process.env.VUE_APP_IMAGE_DESAINS)

    const [route, router, store] = [useRoute(), useRouter(), useStore()]

    const loadingTable = ref(true)
    const loadingButton = ref(false)
    const disabledButton = ref(true)
    const loadingPopover = ref(false)

    const modalVisible = ref(false)
    const modalTitle = ref('')

    const tableData = reactive([])

    const tempData = reactive({
      raw_product_id: null,
      store_id: 1,
      qty: null,
      total_qty: null,
      so_qty: null
    })

    const filterActive = ref(false)
    const filter = reactive({
      kode_dist: null,
    })

    const onFilter = async () => {
      store.dispatch(Actions.ADD_FILTER, filter);
      filterActive.value = false

      await getData()

      Object.values(filter).every(o => o == null || o.length == 0)
        ? filterActive.value = false
        : filterActive.value = true
    }

    const resetFilter = async () => {
      filter.kode_dist = null

      filterActive.value = false

      await getData()
    }

    const pagination = reactive({
      perPage: 50,
      currentPage: 1,
      totalPage: 1,
    })

    const handlePageChange = async (page) => {
      await getData(page)
    }

    const getData = async(page = 1) => {
      tableData.splice(0)
      loadingTable.value = true
      await ApiService.setHeader();
      await ApiService.get("distribusi/list?page="+page, { filter: filter })
        .then(({ data }) => {
          pagination.currentPage = 1
          pagination.totalPage = data.data.pagination.total_page
          pagination.perPage = data.data.pagination.per_page
          Object.assign(tableData, data.data.data)
        })
        .catch(({ response }) => {
          console.log('Error getting history distribusi');
        });

      loadingTable.value = false
    }

    onMounted(async() => {
      setCurrentPageBreadcrumbs("Riwayat Distribusi", ["distribusi", "history"]);

      await Object.assign(filter, store.getters.getFilter);
      await getData()
    })

    return {
      moment,
      route, router,
      loadingTable, base_img_url, modalVisible, modalTitle, disabledButton, loadingButton,
      filter, filterActive, onFilter, resetFilter,
      tableData,
      formatIDR,
      pagination, handlePageChange,
    }
  }
})
</script>